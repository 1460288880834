<template>
  <div id="Setting-Password">
    <div class="setting-password-body">
      <a-form-model
        ref="settingPassword"
        :model="settingPassword.item"
        :rules="settingPassword.rules"
        v-bind="{
          labelCol: { span: 0 },
          wrapperCol: { span: 24 },
        }"
      >
        <a-form-model-item has-feedback prop="OldPassword">
          <a-input-password
            v-model="settingPassword.item.OldPassword"
            autocomplete="off"
            :placeholder="'请输入原' + $route.meta.flag1 + '密码'"
          >
            <a-icon slot="prefix" type="lock" />
          </a-input-password>
        </a-form-model-item>
        <a-form-model-item has-feedback prop="New1Password">
          <a-input-password
            v-model="settingPassword.item.New1Password"
            autocomplete="off"
            :placeholder="'请输入新' + $route.meta.flag1 + '密码'"
          >
            <a-icon slot="prefix" type="lock" />
          </a-input-password>
        </a-form-model-item>
        <a-form-model-item has-feedback prop="New2Password">
          <a-input-password
            v-model="settingPassword.item.New2Password"
            autocomplete="off"
            :placeholder="'请输入设置的新' + $route.meta.flag1 + '密码'"
          >
            <a-icon slot="prefix" type="lock" />
          </a-input-password>
        </a-form-model-item>
        <a-form-model-item>
          <a-button
            id="login"
            @click="submitSettingPassword('settingPassword')"
          >
            确认修改{{ $route.meta.flag1 }}密码
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import "@/components/js/_form_common.js";
export default {
  name: "Setting-Password",
  data() {
    return {
      window,
      settingPassword: {
        item: {
          OldPassword: "",
          New1Password: "",
          New2Password: "",
        },
        rules: {
          OldPassword: [
            {
              required: true,
              whitespace: true,
              trigger: ["change", "blur"],
              validator: (rule, value, callback) => {
                callback(
                  value
                    ? undefined
                    : `原${
                        this.$route.name.split("-")[0] === "pay"
                          ? "支付"
                          : "登录"
                      }密码不能为空`
                );
              },
            },
          ],
          New1Password: [
            {
              required: true,
              whitespace: true,
              trigger: ["change", "blur"],
              validator: (rule, value, callback) => {
                // 密码校验
                const a = new RegExp(/\s+/g); // 空格
                const b = this.settingPassword.item.OldPassword;
                const c = this.settingPassword.item.New1Password;
                const d =
                  this.$route.name.split("-")[0] === "pay" ? "支付" : "登录";
                if (!value) {
                  callback(`新的${d}密码不能为空！（6~18位）`);
                } else if (value.length && value.length < 6) {
                  callback(`新的${d}密码不能小于6位`);
                } else if (value.length && 18 < value.length) {
                  callback(`新的${d}密码不能大于18位`);
                } else if (a.test(value)) {
                  callback(`新的${d}密码中不能包涵空格`);
                } else if (rule.field === "New1Password" && b === c) {
                  callback("新的密码不能与旧的密码相同");
                } else {
                  callback();
                }
              },
            },
          ],
          New2Password: [
            {
              required: true,
              whitespace: true,
              trigger: ["change", "blur"],
              validator: (rule, value, callback) => {
                // 再次密码校验
                const a = new RegExp(/\s+/g); //空格
                const login_pwd = this.settingPassword.item.New1Password;
                const confirm_login_pwd = this.settingPassword.item
                  .New2Password;
                const d =
                  this.$route.name.split("-")[0] === "pay" ? "支付" : "登录";

                if (!value) {
                  callback(`再次输入的新${d}密码不能为空！`);
                } else if (value.length && value.length < 6) {
                  callback(`再次输入的新${d}密码不能小于6位`);
                } else if (value.length && 18 < value.length) {
                  callback(`再次输入的新${d}密码不能大于18位`);
                } else if (a.test(value)) {
                  callback(`再次输入的新${d}密码中不能包涵空格`);
                } else if (login_pwd !== confirm_login_pwd) {
                  callback(`两次输入不一致！`);
                } else {
                  callback();
                }
              },
            },
          ],
        },
      },
    };
  },
  methods: {
    submitSettingPassword(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const data = this[formName].item;
          this.$api("change-password", {
            category: this.$route.name.split("-")[0],
            original: data.OldPassword,
            recent: data.New1Password,
            confirmed: data.New2Password,
          });
        }
      });
    },
  },
};
</script>

<style lang="less" >
#Setting-Password {
}
</style>
